import React from "react"
import { graphql } from "gatsby"
import Filtertron from "../components/Filtertron"

const LifeGroupsPage = ({data}) => (
  <Filtertron
    context                   = "life-groups"
    seoTitle                  = "Life Groups"
    noRecordsPageTitlePrefix  = "Check Back Soon"
    noRecordsPageTitle        = "We're working on something new for Life Groups"
    hasRecordsPageTitlePrefix = ""
    hasRecordsPageTitle       = "Connect with other believers through Life Groups"
    noRecordsIndexTitle       = "No Results"
    noRecordsIndexText        = "trying another selection to find a Life Group."
    collection                = {data.allDatoCmsLifeGroup.edges}
  />
)

export default LifeGroupsPage


// GraphQL
// -------

export const query = graphql`
  query LifeGroupQueryIndex {
    allDatoCmsLifeGroup(
      sort : {fields : position, order : ASC}
    ) {
      edges {
        node {
          ...LifeGroupFragment
        }
      }
    }
  }
`
